// const apiRoot = 'http://office.puldin.eu/NomadnessWebAPI/api'
export var aws_root = 'https://s3.amazonaws.com/nmr-cdn';
export var apiRoot = "".concat(aws_root, "/api/");
export var imageRoot = "".concat(aws_root, "/img/");
export var siteName = "mrbo";
export var platform_apiRoot = 'https://apps.mammothrentalbyowner.com';
export var isTestMode = false;
export var GoogleSiteID = 'G-XRZQX5E8EM';
export var GoogleTagManagerID = 'GTM-WKWS5LG';
export var showBlog = false;
